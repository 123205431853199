import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-around',
    // overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  // gridList: {
  //   width: 750,
  //   height: 400,
  // },
}));

export default function ImageGridList() {

  const data = useStaticQuery(graphql`
    {
      allFile(filter: {sourceInstanceName: {eq: "art"}}) {
        edges {
          node {
            id
            base
            relativePath
            publicURL
          }
        }
      }
    }
  `)

  const classes = useStyles();

  console.log(data)
  return (
    <div className={classes.root}>
      <GridList cellHeight={250} className={classes.gridList} cols={3}>
        {data.allFile.edges.map(({ node }, index) => (
          <GridListTile key={index} cols={1 || 1}>
            <img src={node.publicURL} alt={node.id} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}